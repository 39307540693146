import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["text"]

  toggle(event) {
    const disableList = ['text-disabled', 'hover-dark'];
    const activeList = ['active', 'link-primary'];

    const activeElement = this.element.querySelector('.active');
    const currentElement = event.currentTarget;

    activeElement.classList.remove(...activeList);
    activeElement.classList.add(...disableList);

    currentElement.classList.remove(...disableList);
    currentElement.classList.add(...activeList);

    this.textTarget.text = "View more" + currentElement.text + "articles";
  }
}
