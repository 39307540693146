import { Controller } from '@hotwired/stimulus'

// Controller for range slider input with popup blocks
export default class extends Controller {
  // @target popup, absolute position hidden popup for dynamic information
  // @target description, descriptive paragraph that can change on value
  // @target newDescription, descriptive paragraph that will change on value
  // @target range, range slider input that listens the value
  // @targets data{Target}, dynamic data fields ex. dataProduct
  // @targets button{Target}, dynamic button fields to capture ctas
  // @targets style{Target}, dynamic style fields to change ex. styleBgColour
  // @target value, text/input field to show range value
  // @target mouseOver, area that covers for mouseOver and mouseOut listeners
  static targets = [
    'popup', 'description', 'newDescription', 'range', 'data', 'button', 'value', 'style', 'mouseOver'
  ]

  // @value json[Object] parsed, json data value to match value with dataTargets fields
  // ex.
  //  json: {"0": {"price1": "£2,093", "plan": "essentials"}
  //
  // datas can be dynamic and added more as ex max attributes:
  // "20": {
  //   "value": "20+",
  //   "plan": "Premier+",
  //   "price2": "Premier+",
  //   "price1": "£5,980+",
  //   "price3": "-",
  //   "bgColour": "#12AAED",
  //   "cta": "hubspot-premierplus"
  // }
  static values = {
    json: Object
  }

  initialize() {
    this.initDescription = this.descriptionTarget.textContent
    this.rangeValue = this.rangeTarget.value

    this.showPopup()
    this.updateInnerPopup()
    this.listenRangeSlider()
    this.hidePopup()
  }

  updateInnerPopup() {
    this.loadedData = this.jsonValue[this.rangeValue]

    // this.fillFields();
    if (this.popupTarget && this.loadedData) {
      this.matchAndFillFields()
      if (window.innerWidth > 576) {
        this.updatePopupPosition()
      }
    }
  }

  // we can update popup position just window sizes bigger than 576px
  // native html position should be in place on mobile view sizes.
  updatePopupPosition() {
    const fraction = (this.rangeValue - this.rangeTarget.min) / (this.rangeTarget.max - this.rangeTarget.min)
    const popupWidth = this.popupTarget.scrollWidth
    const adjustment = ((this.rangeValue / this.rangeTarget.max) * this.rangeTarget.clientWidth)
    const left = `calc(${adjustment - (popupWidth / 2) - (fraction + this.rangeTarget.min * 10)}px)`
    this.popupTarget.style.left = left
  }

  updateDescription(value) {
    if (!this.newDescriptionTarget) { return }

    if (value == 1) {
      this.descriptionTarget.innerHTML = this.initDescription
    } else {
      this.descriptionTarget.innerHTML = this.newDescriptionTarget.value
    }
  }

  matchAndFillFields() {
    this.dataTargets.forEach((element, index) => {
      const target = element.dataset.target

      if (this.loadedData[target] == 'undefined') {
        return
      }

      element.textContent = this.loadedData[target]
    })

    this.fillValueText()
    if (this.loadedData['cta']) {
      this.appendCtaButton()
    } else {
      this.styleValueBox()
    }
  }

  fillValueText() {
    // fill Value target, from json data or default range value
    if (this.loadedData['value']) {
      this.valueTarget.textContent = this.loadedData['value']
    } else {
      this.valueTarget.textContent = this.rangeValue
    }
  }

  styleValueBox() {
    this.styleTargets.forEach((element, index) => {
      const target = element.dataset.target
      const styleAttr = element.dataset.styleAttr

      if (this.loadedData[target] == 'undefined') {
        return
      }

      element.style[styleAttr] = this.loadedData[target]
      // Show style box again if selection is not cta button
      this.elementShow(element)
    })

    this.buttonTargets.forEach((element, index) => {
      this.elementHide(element)
    })
  }

  appendCtaButton() {
    this.styleTargets.forEach((element, index) => {
      this.elementHide(element)
    })

    this.buttonTargets.forEach((element, index) => {
      this.elementShow(element)

      const button = element.querySelector('.btn')
      button.style['backgroundColor'] = this.loadedData['bgColour']
      button.classList.add(this.loadedData['cta'])
    })
  }

  elementHide(element) {
    element.classList.remove('d-flex')
    element.classList.add('hidden')
  }

  elementShow(element) {
    element.classList.add('d-flex')
    element.classList.remove('hidden')
  }

  listenRangeSlider() {
    const $this = this
    // input event listener fires when slider moves
    this.rangeTarget.addEventListener('input', function (event) {
      $this.rangeValue = this.value
      $this.updateInnerPopup()
      $this.updateDescription(this.value)
    })

    const mouseElements = this.mouseOverTargets
    mouseElements.forEach((element, index) => {
      if (window.innerWidth < 768) { // Mobile view
        // show popup on touching
        element.addEventListener('touchstart', (event) => {
          clearTimeout($this.timeout)
          $this.showPopup()
        });
        // hide popup when touch end
        element.addEventListener('touchend', (event) => {
          $this.timeout = setTimeout(() => {
            $this.hidePopup(300)
          }, 1000)
        });
      } else { // Desktop view
        // show popup on pointerdown
        element.addEventListener('mouseover', (event) => {
          clearTimeout($this.timeout)
          $this.showPopup()
        });
        // hide popup on pointerleave 1sn timeout
        element.addEventListener('mouseout', (event) => {
          $this.timeout = setTimeout(() => {
            $this.hidePopup(300)
          }, 1000)
        });
      }
    });
  }

  showPopup() {
    this.popupTarget.style.display = 'flex'
    this.popupTarget.style.opacity = 1
  }

  hidePopup(time) {
    // first animate transition opacity to 0
    this.popupTarget.style.opacity = 0
    if (time) {
      // match timeout on display with transition 0.3s and hide
      this.timeout = setTimeout(() => {
        this.popupTarget.style.display = 'none'
      }, time)
    } else {
      this.popupTarget.style.display = 'none'
    }
  }
}
