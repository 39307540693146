import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["content"]

  static values = {
    url: String,
    active: Boolean
  }

  initialize() {
    this.contentElement = this.contentTarget || this.element
  }

  load(event) {
    if (!this.hasUrlValue) { return }

    this.paramValue = "?value=" + event.currentTarget.dataset.value;

    event.preventDefault();
    this.fetch();
  }

  fetch() {
    fetch(this.urlValue + this.paramValue)
      .then((response) => response.text())
      .then((html) => {
        this.contentElement.innerHTML = html
      })
  }
}
