import { Controller } from '@hotwired/stimulus'

// Controller for switch input
export default class extends Controller {
  // @targets data{Target}, dynamic data fields ex. dataProduct
  // @targets button{Target}, button data fields
  static targets = [
    'data', 'button'
  ]

  // @value jsonTrue[Object] parsed, json data value to match value with dataTargets fields when switch true
  // @value jsonFalse[Object] parsed, json data value to match value with dataTargets fields when switch false
  // @value input[String], switch input
  // ex.
  //  jsonTrue: {"plan": "essentials"}
  //  jsonFalse: {"plan": "premier"}
  static values = {
    jsonTrue: Object,
    jsonFalse: Object,
    input: String
  }

  initialize() {
    if (!this.inputValue) { return }

    this.listenSwitchInput()
    this.updateDataFields(this.jsonFalseValue)
  }

  listenSwitchInput() {
    const $this = this

    if (!this.switchInput) { return }

    // input event listener fires when switch input value
    this.switchInput.forEach((element, index) => {
      element.addEventListener('change', function (event) {
        if (this.checked) {
          $this.updateDataFields($this.jsonTrueValue)
        } else {
          $this.updateDataFields($this.jsonFalseValue)
        }

        $this.toggleSwitchClass(element)
        $this.syncSwitches(element)
      })
    })
  }

  syncSwitches(element) {
    const $this = this

    this.switchInput.forEach(function (ele) {
      if (element == ele) { return }

      ele.checked = !!element.checked;
      $this.toggleSwitchClass(ele)
    });
  }

  toggleSwitchClass(element) {
    if (element.checked) {
      element.parentElement.classList.add('switch-button-active')
    } else {
      element.parentElement.classList.remove('switch-button-active')
    }
  }

  updateDataFields(data) {
    if (data == 'undefined') { return }

    this.dataTargets.forEach((element, index) => {
      const target = element.dataset.target

      if (data[target] == 'undefined') {
        return
      }

      element.innerHTML = data[target]
    })

    this.updateButtonFields(data)
  }

  updateButtonFields(data) {
    if (data == 'undefined') { return }

    this.buttonTargets.forEach((element, index) => {
      const target = element.dataset.target
      const dataAttr = element.dataset.targetData

      if (data[target] == 'undefined') {
        return
      }

      element.setAttribute('href', data[target])

      if (dataAttr && data[dataAttr] != 'undefined') {
        element.setAttribute('data-' + dataAttr, data[dataAttr]);
      }
    })
  }

  get switchInput() {
    const elements = document.querySelectorAll('.'+this.inputValue)

    if (elements instanceof NodeList) {
      // true because elements is a NodeList
      return elements
    }

    return
  }
}
